<template>
	<LoginLayout
		:form-title="formTitle"
		portal-name="CS Portal"
	>
		<template #form>
			<div>
				<p>
					We've logged you out
				</p>
				<p>
					To help keep your details secure, we automatically log you out after 15 minutes of inactivity.
				</p>
				<p>
					Please <a @click="redirectToLogin">
						click here
					</a> to return to the login screen.
				</p>
			</div>
		</template>
	</LoginLayout>
</template>

<script>
import LoginLayout from '../../../../shared/layouts/login-layout.vue'

export default {
	components: {
		LoginLayout
	},
	data () {
		return {
			formTitle: 'Session Expired'
		}
	},
	methods: {
		redirectToLogin () {
			setTimeout(() => {
				this.$router.push('/login')
			}, 500) // 500ms delay
		}
	}
}
</script>

<style scoped>
p {
	margin-bottom: 1em;
	font-family: 'Poppins', sans-serif;
}
a {
	cursor: pointer;
	color: blue;
	text-decoration: underline;
}
</style>
